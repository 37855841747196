<template>
  <administration-layout>
    <div class="flex flex-col items-center py-4">
      <policies-and-templates-form :show-checklist-config="true" :show-print-config="true"
        :show-order-status-config="true" />
    </div>
  </administration-layout>
</template>
<script>
export default {
  name: 'OrderPreferences',
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    PoliciesAndTemplatesForm: () => import('@/components/forms/PoliciesAndTemplatesForm'),
  },
}
</script>